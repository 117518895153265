export function setupModalToggles(selector) {
    let modals = document.querySelectorAll(selector);

    if(!modals) {
        return;
    }

    modals.forEach((modal) => {
        let modalId = modal.id;

        document.body.addEventListener('click', (e) => {

						if(false == (e.target.dataset.openModal || e.target.dataset.closeModal || e.target.dataset.toggleModal)) {
							return;
						}

            if(e.target.dataset.openModal && e.target.dataset.openModal !== `#${modalId}`) {
                return;
            }

            if(e.target.dataset.closeModal && e.target.dataset.closeModal !== `#${modalId}`) {
                return;
            }

            if(e.target.dataset.toggleModal && e.target.dataset.toggleModal !== `#${modalId}`) {
                return;
            }

            if(e.target.dataset.openModal) {
                e.preventDefault();
                openModal(modal);

                // load video into modal if present
                if(e?.target?.dataset.videoId) {
                    insertVideo(modal, e.target.dataset.videoId);
                }

								let focusTarget = modal.querySelector('[data-focus-target]');
								if(focusTarget) focusTarget.focus();
            }

            if(e.target.dataset.closeModal) {
                e.preventDefault();
                closeModal(modal);
            }

            if(e.target.dataset.toggleModal) {
                e.preventDefault();
                toggleModal(modal);
            }
        });
    })
}

export function toggleModal(modal) {
    modal?.classList.toggle('is-open');
}

export function openModal(modal) {
    modal?.classList.add('is-open');
		traverseClassName(modal, 'has-open-modal');
}

export function closeModal(modal) {
    modal?.classList.remove('is-open');

    // remove video embed (if present)
    if(modal.querySelector('[data-video-container]')) {
        modal.querySelector('[data-video-container]').innerHTML = '';
    }

		traverseClassName(modal, 'has-open-modal', false);
}

export function toggleModalById(modalId) {
    toggleModal(document.getElementById(modalId));
}

export function openModalById(modalId) {
    openModal(document.getElementById(modalId));
}

export function closeModalById(modalId) {
    closeModal(document.getElementById(modalId));
}

function insertVideo(modal, videoId) {
	const videoContainer = modal.querySelector('[data-video-container]');
    if(videoContainer) {
			let videoWidth = Number(videoContainer.dataset.videoWidth) != NaN ? videoContainer.dataset.videoWidth : '720';
			let videoHeight = Number(videoContainer.dataset.videoHeight) != NaN ? videoContainer.dataset.videoHeight : '400';
			videoContainer.innerHTML = `
            <iframe
                width="${videoWidth}"
                height="${videoHeight}"
                src="https://www.youtube.com/embed/${videoId}?feature=oembed&autoplay=1"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
            ></iframe>
        `;
    }
}

function traverseClassName( el, classname, add = true) {
	var parentEl = el?.parentElement;
	while (parentEl) {
		if(add) parentEl.classList.add(classname);
		else parentEl.classList.remove(classname);
		parentEl = parentEl.parentElement;
	}
}