export default function initProductComparisonCardsCarousel() {
	window.ProductComparisonCardsCarousels = [];
	const carouselBlocks = document.querySelectorAll('.product-comparison-cards-carousel');

	// --- setup IntersectionObserver for fixed-dropdown
	let threshold = 0;
	let options = {
		rootMargin: '0px',
		threshold,
	}

	let activeClass = 'fixed-dropdown--active';

	let setInview = (entries, observer) => {
		entries.forEach(entry => {
			const id = entry.target.getAttribute('id');
			// If the block is in view, check to see if the fixed-dropdown should be active
			if (entry.isIntersecting) {
				window[id] = setInterval(() => {
					const dropdown = entry.target.querySelector('.glide__slide .dropdown-select');
					const viewportOffset = dropdown?.getBoundingClientRect();
					const slideContainer = entry.target.querySelector('.glide__slides');
					const fixedDropdownTrack = entry.target.querySelector('.fixed-dropdowns-track');

					if(dropdown && (viewportOffset?.top < 0)) entry.target.classList.add(activeClass);
					else entry.target.classList.remove(activeClass);

					// Sync the transform on the slides with fixed-dropdown, so the dropdowns follow their respective slides
					if(slideContainer && fixedDropdownTrack) fixedDropdownTrack.style.transform = slideContainer.style.transform;
				}, 300);
			}
			else {
				clearInterval(window[id]);
				entry.target.classList.remove(activeClass);
			}
		});
	}

	let observer = new IntersectionObserver(setInview, options);
	// --- end setup IntersectionObserver

	carouselBlocks.forEach(block => {
		const blockId = block.getAttribute('id');
		if(blockId) window.ProductComparisonCardsCarousels.push(blockId);
		else console.warn('Block missing an id attribute', block);
		observer.observe(block); // --- set IntersectionObserver
	});

	setTimeout( () => {
		if(document.body.classList.contains('is-loaded')) return;
		setTextContainerMaxHeight();
	}, 1000);

	window.addEventListener('load', () => {
		setTextContainerMaxHeight();
		// Safari mobile will trigger 'resize' on scroll, so we test for window.innerWidth changes
		let windowWidth = window.innerWidth;
		window.addEventListener('resize', () => {
			if ($(window).width() != windowWidth) {
				windowWidth = window.innerWidth;
				setTimeout(setTextContainerMaxHeight, 500);
			}
		});
	});

	window.productComparisonCardsDropdownChange = productComparisonCardsDropdownChange;

}

function setTextContainerMaxHeight() {
	window.ProductComparisonCardsCarousels.forEach(blockId => {

		const block = document.getElementById(blockId);
		if(block.dataset.setTextContainerMaxHeight == 'true') return;
		block.dataset.setTextContainerMaxHeight = 'true';
		const slide = block.querySelector('.glide__slide--active');
		block.setAttribute('style', '--text-container-max-height: 10000px; --card-width: '+slide.style.width+';');

		setTimeout(() => {
			const text_wrappers = block.querySelectorAll('.product-comparison-cards-carousel__text-wrapper');
			// Find tallest text-wrapper in the slides
			let max_height = 0;
			for(let i=0; text_wrappers[i]; i++) {
				if(text_wrappers[i].offsetHeight > max_height) max_height = text_wrappers[i].offsetHeight;
			}

			if(max_height) block.setAttribute('style', '--text-container-max-height: '+max_height+'px; --card-width: '+slide.style.width+';');
			else {
				block.setAttribute('style', '--text-container-max-height: 10000px; --card-width: '+slide.style.width+';');
				console.warn('The --text-container-max-height was not able to be set on this block.', block, max_height)
			}

			block.dataset.setTextContainerMaxHeight = 'false';

		}, 500);
	});
}

function productComparisonCardsDropdownChange(el) {
	const dropdownId = el.getAttribute('data-dropdown');
	const primaryDropdown = document.getElementById(dropdownId);
	const cards = primaryDropdown.parentElement.querySelectorAll('.slide-card');
	const allDropdowns = document.querySelectorAll('[data-dropdown="'+dropdownId+'"');

	cards.forEach( card => {
		if(card.classList.contains('option-'+el.value)) {
			card.classList.remove('hidden');
			card.classList.add('active');
		} else {
			card.classList.remove('active');
			card.classList.add('hidden');
		}
	});
	el.blur();

	allDropdowns.forEach( dropdown => {
		if(el === dropdown) return;
		dropdown.value = el.value;
	});
}

