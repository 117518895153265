import Glide from '@glidejs/glide';

export function initDefaultGlide() {
    if(!document.querySelector('[data-glide-carousel]')) {
        return false;
    }

    let carousels = document.querySelectorAll('[data-glide-carousel]');
		let count = 0;
    carousels.forEach((carousel) => {
				count++;
				carousel.setAttribute('data-glide-carousel', count);

        const peek = carousel.dataset.peek || '60';
        const perPage =  carousel.dataset.perPage || '3';
        const gap =   carousel.dataset.gap || '30';
        const autoplay = carousel.dataset.autoplay || '0';

        const glide = new Glide('[data-glide-carousel="'+count+'"]', {
            type: 'slider',
            startAt: 0,
            perView: parseInt(perPage),
            gap: parseInt(gap),
            peek: parseInt(peek),
            autoplay: parseInt(autoplay),
            perSwipe: '|',
            breakpoints: {
                768: {
                    perView: 1,
                    peek: parseInt(carousel.dataset.peek || '30'),
                    gap: 20,
                },
                500: {
                    perView: 1,
                    peek: parseInt(carousel.dataset.peek || '30'),
                    gap: 20,
                    swipeThreshold: 50
                }
            }
        });
        glide.mount();

        return glide;
    });
}

export function initCardGlide(selector = '[data-card-carousel]') {
    let carousels = document.querySelectorAll(selector);
    if(!carousels.length) {
        return false;
    }
		const defaultTimingFunc = 'cubic-bezier(0.165, 0.840, 0.440, 1.000)';
    carousels.forEach((carousel) => {
        const peek = carousel.dataset.peek || '120';
        const perPage =  carousel.dataset.perPage || '3';
        const gap =   carousel.dataset.gap || '30';
				const smallPeek = carousel.dataset.smallPeek || '30';
        const smallGap =   carousel.dataset.smallGap || '15';
        const autoplay = carousel.dataset.autoplay || '0';
				const autoplayAutostart = carousel.dataset.autoplayAutostart || false;
				const mobilePerPage = carousel.dataset.mobilePerPage || '1';
				const tabletPerPage = carousel.dataset.tabletPerPage || '1';
        const widePerPage = carousel.dataset.widePerPage || false;
        const widePeek = carousel.dataset.widePeek || false;
        const wideGap = carousel.dataset.wideGap || false;
        const transitionDuration = carousel.dataset.transitionDuration || '400';
				const type = carousel.dataset.type || 'slider'; // vs 'carousel'
				const hoverpause = carousel.dataset.hoverpause || false;
				const animationTimingFunc = carousel.dataset.animationTimingFunc || defaultTimingFunc;
				const stepDown = carousel.dataset.stepDown === 'true' || false;
				const bound = carousel.dataset.bound || false;
				const rewindDuration = carousel.dataset.rewindDuration || 800;

        // hide bullets if not enough items
        const itemCount = carousel.querySelectorAll('.glide__item').length;
        if((window.innerWidth >= 768 && itemCount <= perPage) || (window.innerWidth < 768 && itemCount === 1)) {
            carousel.querySelector('.glide__bullets')?.classList.add('hidden');
        }

        if(carousel.dataset.mobileDisabled && window.innerWidth < 768) {
            return;
        }

        if(carousel.dataset.mobileOnly) {
            if(window.innerWidth >= 768) {
                carousel.classList.add('is-ready');
                return;
            }
            else {
                const items = carousel.querySelectorAll('.glide__item');
                carousel.querySelector('[data-glide-el="track"]')?.classList.add('glide__track');
                carousel.querySelector('[data-glide-slides]')?.classList.add('glide__slides');
                items.forEach((item) => {
                    item.classList.remove('flex-col');
                    item.classList.remove('flex-col-4');
                });
            }
        }

        const args = {
            type,
            startAt: 0,
            perView: parseInt(perPage),
						bound,
						rewindDuration,
            gap: parseInt(gap),
            peek: parseInt(peek),
            autoplay: parseInt(autoplay),
            animationDuration: parseInt(transitionDuration),
						animationTimingFunc,
						hoverpause,
            breakpoints: {
                768: {
                    perView: parseInt(tabletPerPage),
                    peek: (carousel.dataset.smallPeek) ? parseInt(smallPeek) : 50,
										gap: (carousel.dataset.smallGap) ? parseInt(smallGap) : parseInt(gap),
										animationDuration: 400,
										autoplay: (parseInt(autoplay)) ? 7000 : 0,
										animationTimingFunc: defaultTimingFunc,
                },
                500: {
                    perView: parseInt(mobilePerPage),
                    peek: parseInt(smallPeek),
                    gap: parseInt(smallGap),
                    swipeThreshold: 50
                }
            }
        }

        if(widePerPage) {
            args.breakpoints[1400] = {
                perView: parseInt(widePerPage),
                gap: parseInt(wideGap),
                peek: (widePeek !== false) ? parseInt(widePeek) : parseInt(peek),
            };
        }

				if(stepDown) {
					args.gap = parseInt(wideGap);
					args.peek = parseInt(widePeek) || parseInt(peek);

					args.breakpoints[1400] = args.breakpoints[1400] || {};

					args.breakpoints[1400].perView = 2;
					args.breakpoints[1400].gap = parseInt(gap);
					args.breakpoints[1400].peek = parseInt(peek);

					args.breakpoints[768].perView = 1;
					args.breakpoints[768].gap = parseInt(gap);
					args.breakpoints[768].peek = parseInt(peek);
				}

        const glide = new Glide(`#${carousel.id}`, args);
        glide.mount();
				if(autoplayAutostart) {
					glide.go('>');
				}

    });
}

export function initVideoShortsGlide(selector = '[data-video-shorts-carousel]') {
	let carousels = document.querySelectorAll(selector);
	if(!carousels.length) {
			return false;
	}
	const defaultTimingFunc = 'linear';
	carousels.forEach((carousel) => {
			const peek = carousel.dataset.peek || '200';
			const perPage =  carousel.dataset.perPage || '7';
			const gap =   carousel.dataset.gap || '36';
			const autoplay = carousel.dataset.autoplay || '1';
			const autoplayAutostart = carousel.dataset.autoplayAutostart || true;
			const transitionDuration = carousel.dataset.transitionDuration || '12000';
			const type = carousel.dataset.type || 'carousel';
			const hoverpause = carousel.dataset.hoverpause || true;
			const animationTimingFunc = carousel.dataset.animationTimingFunc || defaultTimingFunc;
			const bound = carousel.dataset.bound || true;
			const rewindDuration = carousel.dataset.rewindDuration || 0;
			// const smallPeek = carousel.dataset.smallPeek || '100';
			// const smallGap =   carousel.dataset.smallGap || '20';
			// const mobilePerPage = carousel.dataset.mobilePerPage || '1';
			// const tabletPerPage = carousel.dataset.tabletPerPage || '3';
			// const widePerPage = carousel.dataset.widePerPage || '4';
			// const widePeek = carousel.dataset.widePeek || '200';
			// const wideGap = carousel.dataset.wideGap || '36';


			const args = {
					type,
					startAt: 0,
					perView: parseInt(perPage),
					bound,
					rewindDuration,
					gap: parseInt(gap),
					peek: parseInt(peek),
					autoplay: parseInt(autoplay),
					animationDuration: parseInt(transitionDuration),
					animationTimingFunc,
					hoverpause,
					breakpoints: {
							2200: {
								perView: 6,
								gap: 36,
							},
							2000: {
								perView: 5,
								gap: 36,
							},
							1600: {
								perView: 4,
								gap: 36,
							},
							1300: {
								perView: 3,
								gap: 36,
								peek: 250,
							},
							1100: {
								perView: 3,
								gap: 34,
								peek: 200,
								animationDuration: 10000,
							},
							950: {
								perView: 3,
								gap: 34,
								peek: 120,
								animationDuration: 9000,
							},
							768: {
								perView: 2,
								gap: 34,
								peek: 120,
								animationDuration: 8000,
							},
							600: {
								perView: 1,
								peek: 150,
								gap: 34,
								swipeThreshold: 50,
								animationDuration: 8000,
							},
							450: {
								perView: 1,
								peek: 140,
								gap: 26,
								swipeThreshold: 50,
								animationDuration: 7000,
							},
							400: {
								perView: 1,
								peek: 100,
								gap: 26,
								swipeThreshold: 50,
								animationDuration: 7000,
							},
							350: {
								perView: 1,
								peek: 80,
								gap: 24,
								swipeThreshold: 50,
								animationDuration: 7000,
							},
					}
			}


			const glide = new Glide(`#${carousel.id}`, args);
			glide.mount();
			if(autoplayAutostart) {
				glide.go('>');
			}
			if(hoverpause) {
				window[carousel.id]['glide'] = glide;
				const glide_el = document.querySelector(`#${carousel.id}`);
				glide_el.addEventListener('mouseover', function () {

					clearInterval(window[carousel.id]['open-modal-watch-interval']);
					clearInterval(window[carousel.id]['pause-interval']);

					glide.pause();

					const glide__slides = this.querySelector('.glide__slides');

					// Save original transition and transform values for mouseleave
					window[carousel.id]['transition'] = glide__slides.style.transition
					window[carousel.id]['transform'] = glide__slides.style.transform

					// Set transition transform time to be imperceptibly slow, since we can't pause a transform animation
					glide__slides.style.transition = 'transform 9999999ms linear 0s';

					// Parse out the translate3d X value, and add 1px to trigger a new CSS transform state
					let transform = glide__slides.style.transform
					let transform_start = transform.split('translate3d(');
					let transform_end = transform_start[1].split('px');
					let transform_val = parseInt(transform_end[0]);

					glide__slides.style.transform = `translate3d(${transform_val + 1}px, 0px, 0px)`;

					window[carousel.id]['pause-interval'] = window.setInterval(()=> {
						glide__slides.style.transition = 'transform 9999999ms linear 0s';
						glide__slides.style.transform = `translate3d(${transform_val + 1}px, 0px, 0px)`;
					}, 200);

				});

				glide_el.addEventListener('mouseout', function () {

					const glide__slides = this.querySelector('.glide__slides');

					// Reset to original transition and transform values
					glide__slides.style.transition = window[carousel.id]['transition'];
					glide__slides.style.transform = window[carousel.id]['transform'];

					// Opening a modal will trigger 'mouseout', so we set an interval to watch for when it closes
					if(document.body.classList.contains('has-open-modal')) {
						window[carousel.id]['open-modal-watch-interval'] = window.setInterval(()=> {

							if(document.body.classList.contains('has-open-modal')) return;

							// Clear all intervals for good measure and restart glide
							clearInterval(window[carousel.id]['open-modal-watch-interval']);
							clearInterval(window[carousel.id]['pause-interval']);
							window[carousel.id]['glide'].play();
							window[carousel.id]['glide'].go('>');

						}, 2000);
					}
					// This was just a normal 'mouseout'
					else {
						// Clear all intervals for good measure and restart glide
						clearInterval(window[carousel.id]['open-modal-watch-interval']);
						clearInterval(window[carousel.id]['pause-interval']);
						window[carousel.id]['glide'].play();
						window[carousel.id]['glide'].go('>');
					}
				})
			}

	});
}
export function initTabsGlide(selector = '[data-glide-carousel-tabs]') {
    if(!document.querySelector(selector)) {
        return false;
    }

    const glide = new Glide(selector, {
        type: 'carousel',
        startAt: 0,
        perView: 6,
        peek: 80,
        focusAt: 'center',
        gap: 20,
        dragThreshold: false,
        breakpoints: {
            1500: {
                perView: 4,
                peek: 80
            },
            768: {
                perView: 1,
                peek: 100
            },
            500: {
                perView: 1,
                peek: 100,
                swipeThreshold: 50
            }
        }
    });
    glide.mount();

    return glide;
}


//apps we love slider
if(document.querySelector('.glide-app-reviews')) {
    new Glide('.glide-app-reviews', {
        type: 'carousel',
        perView: 1,
        startAt: 1,
        perTouch: 1,
        focusAt: 'center',
        autoplay: 6000,
        peek: 900,
        gap: 70,
        breakpoints: {
            2450: {
                peek: 750,
                perView: 1
            },
            2150: {
                peek: 650,
                perView: 1
            },
            1850: {
                peek: 500,
                perView: 1
            },
            1550: {
                peek: 350,
                perView: 1
            },
            1250: {
                peek: 300,
                perView: 1
            },
            1200: {
                peek: 200,
                gap: 50,
                perView: 1
            },
            1000: {
                peek: 150,
                gap: 50,
                perView: 1
            },
            835: {
                peek: 120,
                gap: 50,
                perView: 1
            },
            767: {
                peek: 0,
                gap: 50,
                perView: 1
            }
        }
    }).mount()
}
export function goToSlide(id, index) {
	var glide = new Glide('#'+id).mount();
	glide.go('='+index);
}

export function initButtonTrack() {
	const buttons = document.querySelectorAll('.glide__button-track .button');
	if(buttons.length == 0) return;

	const config = { attributes: true, childList: false, subtree: false };

	const callback = (mutationList, observer) => {
		for (const mutation of mutationList) {
			if (mutation.type === "attributes") {
				if(mutation.target.classList.contains('glide__bullet--active')) {

					let leftAlignMobile = true;

					let targetOffsetLeft = mutation.target.offsetLeft;
					let parentElPaddingLeft = parseFloat(window.getComputedStyle(mutation.target.parentElement, null).getPropertyValue('padding-left'));
					let translateX = 0;
					let centerScreen = (window.innerWidth / 2);
					let targetCenter =  (mutation.target.offsetWidth / 2);

					if(leftAlignMobile || window.innerWidth > 768) { // Desktop
						translateX = targetOffsetLeft - parentElPaddingLeft;
						mutation.target.parentElement.style.transform = 'translateX(calc('+translateX+'px * -1))';
					}

					else { // Mobile
						let margin = 50;
						if(window.innerWidth <= 500 ) margin = 36;
						translateX = centerScreen - (targetOffsetLeft + targetCenter);
						mutation.target.parentElement.style.transform = 'translateX(calc('+translateX+'px - '+margin+'px))';
					}
				}
			}
		}
	};

	const observer = new MutationObserver(callback);

	buttons.forEach((button) => {
		observer.observe(button, config);
	});
}

export function initFullWidthCarouselBlock() {
	const glideSlides = document.querySelectorAll('.full-width-carousel .glide__slides');
	glideSlides.forEach((glideSlide) => {
		glideSlide.addEventListener('transitionrun', (event) => {
			const target_id = glideSlide.dataset.target;
			if(target_id == undefined) return;
			handleSlideChangeFullWidthCarouselContent(target_id);
		});
	})
}

function handleSlideChangeFullWidthCarouselContent(target_id) {
	const carousel = document.getElementById(target_id);
	if(carousel == undefined) return;

	if(window.innerWidth >= 1024) {
		carousel.classList.remove('slider-fade-in');
		carousel.classList.add('slider-fade-out');

		setTimeout(() => {
			carousel.classList.add('slider-fade-in');
			carousel.classList.remove('slider-fade-out');
		}, 500);
	}

	syncFullWidthCarouselContent(target_id);
}

function syncFullWidthCarouselContent(target_id) {

	if(window['syncFullWidthCarouselContent-'+target_id] == 'running') return;
	else window['syncFullWidthCarouselContent-'+target_id] = 'running';

	const carouselBlock = document.getElementById('block-'+target_id);
	if(carouselBlock ==  undefined) return;

	const mobileContainer = carouselBlock.querySelector('.full-width-carousel__content-container--mobile');
	mobileContainer?.classList.remove('slider-fade-in');
	mobileContainer?.classList.add('slider-fade-out');

	setTimeout(() => {
		const desktopSlides = carouselBlock.querySelectorAll('.glide__container--desktop .glide__item');

		let active = 0;
		for(let i=0; desktopSlides[i]; i++) {
			if(desktopSlides[i].classList.contains('glide__slide--active')) {
				active = i;
				break;
			}
		}

		const mobileSlides = carouselBlock.querySelectorAll('.full-width-carousel__content-container--mobile .glide__item');
		for(let i=0; mobileSlides[i]; i++) {
			if(i == active) {
				mobileSlides[i].classList.add('glide__slide--active')
			}
			else {
				mobileSlides[i].classList.remove('glide__slide--active')
			}
		}

		mobileContainer?.classList.add('slider-fade-in');
		mobileContainer?.classList.remove('slider-fade-out');

		window['syncFullWidthCarouselContent-'+target_id] = 'done';
	}, 250);
}