export function getPaginatedPosts(post_type = 'posts', params = {per_page: 9, page: 1}) {
	params.per_page = params.per_page ?? 9;
	params.page = params.page ?? 1;

	let fetch_url = `/wp-json/wp/v2/${post_type}/`;
	let url_params = [];
	for (const [key, value] of Object.entries(params)) {
		url_params.push(`${key}=${value}`);
	}
	if(url_params.length) {
		fetch_url+=`?${url_params.join('&')}`;
	}
	return fetch(fetch_url)
		.then(response => response.json())
		.then(data => {
			return data;
		})
		.catch(error => console.warn(error));
}

export function showMorePostArchive(target_id, exclude_ids = [] ) {

	if(exclude_ids.length == 0) {
		const exclude_data = document.querySelector('[data-exclude-'+target_id+']');
		exclude_ids = exclude_data.getAttribute('data-exclude-'+target_id);
		exclude_ids = JSON.parse(exclude_ids);
	}

	window.postArchivePage = (window.postArchivePage) ? window.postArchivePage + 1 : 1;
	const page = window.postArchivePage;
	const per_page = 9;
	const exclude = exclude_ids;
	let params = {per_page, page, exclude};

	const button = document.querySelector('#show-more-posts-button');

	button.classList.add('disabled');

	getPaginatedPosts('posts', params).then(response => {
		if(! Array.isArray(response) || response.length < per_page ) {
			button.style.display = 'none';
		}
		const template = document.querySelector('#posts-archive--list-item-template');
		const target = document.querySelector('#posts-archive--list-'+target_id);
		if(Array.isArray(response)) {
			response.forEach(post => {
				let li = template.content.cloneNode(true);
				li.querySelector('.image-container').setAttribute('href', post.link ?? '');
				li.querySelector('.image-container img').setAttribute('src', post.featured_image_url_9x5 ?? post.featured_image_url);
				li.querySelector('.category-link').innerHTML = post.primary_category.name ?? '';
				li.querySelector('.category-link').setAttribute('href', post.primary_category_link ?? '');
				li.querySelector('h2 > a').innerHTML = post.title.rendered ?? '';
				li.querySelector('h2 > a').setAttribute('href', post.link ?? '');
				li.querySelector('.blog-date').innerHTML = post.pretty_date ?? '';

				target.appendChild(li);
			})

			button.classList.remove('disabled');

		}
	})

}


export function showMoreTechGuides(start = 1, taxonmomy = '', term_id = 0) {

	window.techGuidesPage = (window.techGuidesPage) ? window.techGuidesPage + 1 : start;
	const page = window.techGuidesPage;
	const per_page = 9;
	let params = {per_page, page};

	if('' !== taxonmomy && term_id) {
		params[taxonmomy] = term_id;
	};
	const button = document.querySelector('#show-more-button');

	button.classList.add('disabled');

	getPaginatedPosts('tech-guide', params).then(response => {
		if(! Array.isArray(response) || response.length < per_page ) {
			button.style.display = 'none';
		}
		const template = document.querySelector('#card-template');
		const target = document.querySelector('#show-more-target');
		if(Array.isArray(response)) {
			response.forEach(post => {
				let card = template.content.cloneNode(true);
				card.querySelector('h4').innerHTML = post.title.rendered ?? '';
				card.querySelector('p.app-description').innerHTML = post.excerpt.rendered ?? '';
				card.querySelector('a').setAttribute('href', post.link ?? '');
				target.appendChild(card);
			})

			button.classList.remove('disabled');

		}
	})
}